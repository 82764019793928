import React from "react";
import { graphql } from "gatsby";

import { mainNavigation, homesNavigation } from "src/data";
import Header from "components/Header";
import Banner from "components/Banner";
import Layout from "components/Layout";
import Footer from "components/Footer";
import Button from "components/Button";

import "styles/styles.scss";

const IndexPage = ({ data }) => {
  const { logo, content } = data;
  return (
    <>
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logo.childImageSharp.fluid}
        logoAlt="Moodu moduliniai namai"
      />
      {content.edges.map((item, idx) => (
        <React.Fragment key={idx}>
            <Banner
                    heading="Puslapis nerastas"
                    isInnerPage
                    imageData={item.node.image.fluid}
                    isCentered
                />
      <Layout>
      <p>Deja, bet puslapis, kurio ieškote neegzistuoja. Patikrinkite savo užklausą.</p>
      <Button url="/" isMain>
              Grįžti į pagrindinį
            </Button>
      </Layout>
      <Footer logo={logo.childImageSharp.fluid} />
      </React.Fragment>
      ))}
    </>
  );
};

export const query = graphql`
  query Error {
    logo: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200 ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    content: allContentfulPrivacyPolicy {
        edges {
          node {
            image {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
  }
`;

export default IndexPage;
